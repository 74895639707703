@import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities";

:root {
  --bg-color: rgb(20, 20, 20);
}

body {
  background-color: var(--bg-color);
  height: auto;
}

html {
  height: auto;
}
